import React, {FC} from "react";
import './style.css'
import {ui, hooks} from "../../../ui";
import {useDispatch} from "react-redux";
import {methods} from "../../../../api/methods";
import {Thunk} from "../../../../store/DealReducer";

const {
    AlertContainer,
    Textarea,
    Button,
    Input,
} = ui


export const Feedback: FC = () => {


    /* hooks */
    const dispatch = useDispatch()
    const [getters, setters] = methods.useSetters({initialData: false})
    const {state, onChange, onSubmit} = hooks.useCallbackForm(getters.errors);
    const {startArbitration} = Thunk(setters)


    const handleNewFeedback = () => setters.setData(false)

    const handleSend = () => {
        if (onSubmit()) {
            dispatch(startArbitration({
                email: state.email.value,
                message: state.message.value,
                fullName: state.fullName.value
            }))
        }
    }

    const FeedbackForm = (<>
        <div className='Feedback__title'>
            У вас остались вопросы?
        </div>
        <div className='Feedback__subtitle'>
            Оставьте свои контактные данные и наш специалист в ближайшее время свяжется с вами
        </div>
        <form className='Feedback__form'>
            <Input
                label='Имя'
                placeholder='Имя'
                className='Feedback__input'
                id='fullName'
                onChange={onChange}
                {...state.fullName}
            />
            <Input
                label='Почта'
                placeholder='Почта'
                className='Feedback__input'
                id='email'
                onChange={onChange}
                {...state.email}
            />
            <Textarea
                label='Сообщение'
                placeholder='Сообщение'
                id='message'
                onChange={onChange}
                {...state.message}
            />
        </form>
        <Button onClick={handleSend}>Отправить</Button>
    </>)

    const FeedbackSuccess = (<>
        <div className='Feedback__subtitle Feedback__subtitle--success'>
            <span>
                Спасибо, Ваше сообщение будет рассмотрено в ближайшее время.Вы получите ответ на почту.
            </span>
        </div>
        <Button onClick={handleNewFeedback}>Написать ещё</Button>
    </>)


    return (
        <section className='Feedback Grid'>
            <AlertContainer id="Feedback">
                {getters.data ? FeedbackSuccess : FeedbackForm}
            </AlertContainer>
            <div className='Feedback__bottom-line'/>
        </section>
    )
}