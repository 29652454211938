import React, {useEffect, useReducer} from "react";
import {IErrors, IErrorsFront} from "../../../api/types";
import {methods} from "../../../api/methods/";


type ErrorsType = IErrors | IErrorsFront

export const useCallbackForm = (errors?: ErrorsType[]) => {

    useEffect(() => {
        if (errors?.length) {
            errors.map((item: ErrorsType) => {
                dispatch({
                    type: `@useCallbackForm/set_${item.field}`,
                    field: 'error',
                    payload: methods.sliceAfter(typeof item.message === "string" ? item.message : '', ':')
                })
            })
        }
    }, [errors])

    /* state */
    const SET_EMAIL = '@useCallbackForm/set_email';
    const SET_MESSAGE = '@useCallbackForm/set_message';
    const SET_FULL_NAME = '@useCallbackForm/set_fullName';

    /* reducer */
    const reducer = (state: any, action: any) => {
        switch (action.type) {
            case SET_EMAIL:
                return {
                    ...state,
                    email: {...state.email, [action.field]: action.payload}
                }
            case SET_FULL_NAME:
                return {
                    ...state,
                    fullName: {...state.fullName, [action.field]: action.payload}
                }
            case SET_MESSAGE:
                return {
                    ...state,
                    message: {...state.message, [action.field]: action.payload}
                }
            default:
                return state;
        }
    }

    /* hook */
    const [state, dispatch] = useReducer(reducer, {
        email: {
            error: '',
            value: '',
        },
        fullName: {
            error: '',
            value: '',
        },
        message: {
            error: '',
            value: '',
        }
    })

    /* methods */
    const handleSubmit = (): boolean => {
        Object.entries(state).map((item: any) => {
            const [name, field] = item;
            dispatch({
                type: `@useCallbackForm/set_${name}`,
                field: 'error',
                payload: field.value ? "" : "Поле обязательно к заполнению!"
            })
        })

        const filledFields: boolean = (
            !!state.email.value
            && !!state.fullName.value
            && !!state.message.value
        )
        return filledFields
    }

    const handleChange = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        if (event.target.id) {
            dispatch({
                type: `@useCallbackForm/set_${event.target.id}`,
                field: 'value',
                payload: event.target.value
            })
            dispatch({
                type: `@useCallbackForm/set_${event.target.id}`,
                field: 'error',
                payload: ''
            })
        }
    }

    return {
        state,
        onSubmit: handleSubmit,
        onChange: handleChange,
    }
}
