import React, {FC, Fragment} from "react";
import './style.css'
import {Feedback} from "../../uiSections/Feedback";
import {hooks} from "../../../ui";


export const ServiceRules: FC = () => {

    hooks.useScrollToTop()

    return (
        <Fragment>
            <section className='ServiceRules Grid Page'>
                <h2 className='PageTitle'>Правила сервиса</h2>
                <div className='ServiceRules__rules'>
                    <div>
                        <b>Публичная оферта об использовании Платежного сервиса «Безопасный перевод»</b>
                    </div>
                    <div>
                        <b>Public offer on the use of the “Safe Transfer” Payment Service</b>
                    </div>
                    <div>
                        Предлагаем Вам ознакомиться с текстом публичной оферты, в которой содержатся все существенные
                        условия Соглашения об использовании Платежного сервиса «Безопасный перевод» . В случае согласия
                        с условиями предлагаемой публичной оферты Соглашение об использовании Платежного сервиса
                        «Безопасный перевод» считается заключенным с момента совершения Вами всех необходимых действий,
                        указанных в публичной оферте, и означает Ваше согласие со всеми, без исключения и дополнения,
                        условиями Соглашения об использовании Платежного сервиса «Безопасный перевод» .
                    </div>
                    <div>
                        We invite you to look through the text of the public offer, which contains all the essential
                        terms of the Agreement on the use of the “Safe Transfer” Payment.
                        In case of acceptance of the terms of the proposed public offer, the Agreement
                        on the use of the “Safe Transfer” Payment Service is considered to be made from
                        the moment you perform all the necessary actions specified in the public offer, and
                        that means your acceptance of all the terms of the Agreement on the use of
                        the “Safe Transfer” Payment Service without any exceptions and additions.
                    </div>
                </div>
            </section>
            <Feedback/>
        </Fragment>
    )
}