export const SubmitData = (props) => {

    window.document.write(`
        <form hidden id="form" method="post" action="${props.acsUrl}" >
        <input hidden name="Md" value="${props.requestId}" />
        <input hidden name="PaReq" value="${props.paReq}" />
        <input hidden name="TermUrl" value="${window.location.origin}/api/v1/deal/${props.dealId}/approve"/>
        <input hidden type="submit" value="Отправить" />
        </form>
    `);
    window.document.querySelector('#form').submit();
}

