import React, {Fragment} from 'react'
import './style.css'
import {Route, Switch} from 'react-router-dom'
import {CreatingDeal} from "./CreatingDeal";
import {InformationOfDeal} from "./InformationOfDeal";
import {Arbitration} from "./Arbitration";
import {CardConnect} from "./CardConnect";
import {GetStatus} from "./GetStatus";

export const Deal = () => {

    return (
        <Fragment>
            <Switch>
                <Route path='/deal/creating-deal'>
                    <CreatingDeal/>
                </Route>
                <Route path='/deal/deal-info'>
                    <InformationOfDeal/>
                </Route>
                <Route path='/deal/arbitration'>
                    <Arbitration/>
                </Route>
                <Route path='/deal/card-connect'>
                    <CardConnect/>
                </Route>
                <Route path='/deal'>
                    <GetStatus/>
                </Route>
            </Switch>
        </Fragment>
    )
}