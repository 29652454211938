import React from 'react'
import './style.css'
import {Route} from 'react-router-dom';
import {ArbitrationForm} from "./ArbitrationForm";
import {SuccessStatus} from "./SuccessStatus";
import {hooks} from "../../ui";

export const Arbitration = () => {

    hooks.useScrollToTop()

    return (
        <section className='Grid DealPage Arbitration'>
            <h2 className='DealPageTitle'>Написать в арбитраж</h2>
            <Route exact path='/deal/arbitration'>
                <ArbitrationForm/>
            </Route>
            <Route path='/deal/arbitration/success'>
                <SuccessStatus/>
            </Route>
        </section>
    )
}