import {ISetters} from '../../../api/types'
import {Dispatch} from 'redux'
import {Client, Deal, Feedback} from '../../../api'
import {actions} from '../index'
import {StateType} from '../../types'
import {DealReducerActionType} from '../types'
import {AxiosResponse} from "axios";


interface IFio {
    lastName: string
    firstName: string
    middleName: string
}

interface IGetClient {
    email: string,
    cardNumber?: string
    clientType: "setSeller" | "setBuyer"
    buyerFio: IFio
    sellerFio: IFio
}

interface IUpdateClient {
    clientId: number
    lastName: string
    firstName: string
    middleName: string
    email: string
    cardNumber?: string
}

interface ICreateDeal {
    name: string
    amount: number
    description: string
}

interface IGetDealStatus {
    dealId: number
}

interface IDealApprove {
    dealId: number
    paRes: string
}

interface IStateDeal {
    dealId: number
    cardNumber: number
    expYear: number
    expMonth: number
    cvv: string
    cardHolder: string | null
}

interface IArbitration {
    email: string
    message: string
    fullName: string
}

interface ISetCardNumberForSeller {
    email: string,
    clientId: number,
    lastName: string,
    firstName: string,
    middleName: string,
    cardNumber: string
}

export const DealReducerThunk = (setters: ISetters) => {
    return {
        getClient: (props: IGetClient) => (dispatch: Dispatch<DealReducerActionType>, getState: () => StateType) => {

            const {email, clientType} = props;

            /* Update data of client */
            const setCardNumberForSeller = async (props: ISetCardNumberForSeller) => {
                if (clientType === 'setSeller') {
                    const response = await Client.update({
                        email: props.email,
                        clientId: props.clientId,
                        lastName: props.lastName,
                        firstName: props.firstName,
                        middleName: props.middleName,
                        cardNumber: props.cardNumber
                    })
                    switch (response.status) {
                        case 204:
                            setters.setData(true)
                            break
                        case 400:
                            setters.setErrors(response.data.data.errors);
                            setters.setData(false)
                            break;
                        default:
                            break
                    }
                }
            }

            Client.get({email}).then((response: AxiosResponse<any>) => {
                switch (response.status) {
                    case 200:
                        dispatch(actions[clientType]({
                            email: email,
                            id: response.data.data.id,
                            lastName: response.data.data.last_name,
                            firstName: response.data.data.first_name,
                            middleName: response.data.data.middle_name,
                        }))
                        setCardNumberForSeller({
                            email: email,
                            clientId: response.data.data.id,
                            lastName: response.data.data.last_name,
                            firstName: response.data.data.first_name,
                            middleName: response.data.data.middle_name,
                            cardNumber: String(props.cardNumber)
                        }).finally()
                        return response
                    default:
                        return response
                }
            }).then((response: AxiosResponse<any>) => {
                if (response.status === 404) {
                    const clientFio = clientType === "setSeller" ? props.sellerFio : props.buyerFio
                    Client.create({email: email, ...clientFio}).then((response: AxiosResponse<any>) => {
                        switch (response.status) {
                            case 201:
                                dispatch(actions[clientType]({
                                    email: email,
                                    id: response.data.data.id,
                                    lastName: response.data.data.last_name,
                                    firstName: response.data.data.first_name,
                                    middleName: response.data.data.middle_name,
                                }));
                                setCardNumberForSeller({
                                    email: email,
                                    clientId: response.data.data.id,
                                    lastName: response.data.data.last_name,
                                    firstName: response.data.data.first_name,
                                    middleName: response.data.data.middle_name,
                                    cardNumber: String(props.cardNumber)
                                }).finally()
                                return response;
                            case 400:
                                setters.setErrors(response.data.data.errors)
                                return response;
                            default:
                                return response;
                        }
                    })
                }
            });
        },
        updateClient: (props: IUpdateClient) => async (dispatch: Dispatch<DealReducerActionType>, getState: () => StateType) => {
            const response: AxiosResponse<any> = await Client.update({
                email: props.email,
                clientId: props.clientId,
                lastName: props.lastName,
                firstName: props.firstName,
                middleName: props.middleName,
                cardNumber: props.cardNumber
            })
            switch (response.status) {
                case 204:
                    dispatch(actions.setSeller({
                        id: props.clientId,
                        email: props.email,
                        lastName: props.lastName,
                        firstName: props.firstName,
                        middleName: props.middleName,
                    }))
                    setters.setData(false);
                    break
                case 400:
                    setters.setErrors(response.data.data.errors)
                    break
                default:
                    break;
            }
        },
        createDeal: (props: ICreateDeal) => async (dispatch: Dispatch<DealReducerActionType>, getState: () => StateType) => {
            const response: AxiosResponse<any> = await Deal.create({
                name: props.name,
                amount: props.amount,
                description: props.description,
                sellerId: Number(getState().DealReducer.seller?.id),
                buyerId: Number(getState().DealReducer.buyer?.id)
            });
            switch (response.status) {
                case 201:
                    dispatch(actions.setSum(response.data.data.amount));
                    dispatch(actions.setDealId(response.data.data.id));
                    dispatch(actions.setDeal({
                        description: props.description,
                        id: response.data.data.id,
                        amount: props.amount,
                        name: props.name
                    }))
                    setters.history.push('/deal/deal-info')
                    break
                case 400:
                    setters.setErrors(response.data.data.errors)
                    break;
                default:
                    break;
            }
        },
        getDealStatus: (props: IGetDealStatus) => async (dispatch: Dispatch<DealReducerActionType>, getState: () => StateType) => {

            const caseError = '/deal/card-connect/error';
            const caseSuccess = '/deal/card-connect/success';

            const response: AxiosResponse<any> = await Deal.get({dealId: props.dealId})

            if (response.status === 200) {
                switch (response.data.data.status) {
                    /* пингуем */
                    case "started":
                        dispatch(actions.setPaReq(response.data.data.pa_req))
                        dispatch(actions.setAcsUrl(response.data.data.acs_url))
                        dispatch(actions.setRequestId(response.data.data.request_id))
                        setters.setLoading(true)
                        break;
                    case "sent_process":
                        setters.setLoading(true)
                        break
                    case "new":
                        setters.setLoading(true)
                        break
                    case "approved":
                        setters.setLoading(true)
                        break
                    /* редирект на страницу ошибки */
                    case "rejected":
                        setters.history.push(caseError)
                        setters.setLoading(false)
                        break
                    case "error":
                        setters.history.push(caseError)
                        setters.setLoading(false)
                        break
                    /* редирект на страницу успеха */
                    case "completed":
                        setters.history.push(caseSuccess)
                        setters.setLoading(false)
                        break
                    default:
                        setters.setLoading(true)
                }
            }
        },
        dealApprove: (props: IDealApprove) => async (dispatch: Dispatch<DealReducerActionType>, getState: () => StateType) => {
            const response: AxiosResponse<any> = await Deal.approve({
                dealId: props.dealId,
                paRes: props.paRes
            })
            switch (response.status) {
                case 204:
                    // setters.setLoading(false)
                    break;
                default:
                    setters.setLoading(true)
            }
        },
        startDeal: (props: IStateDeal) => async (dispatch: Dispatch<DealReducerActionType>, getState: () => StateType) => {
            const response: AxiosResponse<any> = await Deal.start({
                cardHolder: props.cardHolder,
                cardNumber: props.cardNumber,
                expMonth: props.expMonth,
                expYear: props.expYear,
                dealId: props.dealId,
                cvv: props.cvv
            });
            switch (response.status) {
                case 204:
                    setters.history.push('/deal/card-connect/loading')
                    setters.setLoading(true)
                    break;
                case 400:
                    setters.setErrors(response.data.data.errors)
                    setters.setLoading(false)
                    break
                default:
                    break;
            }
        },
        startArbitration: (props: IArbitration) => async (dispatch: Dispatch<DealReducerActionType>, getState: () => StateType) => {
            const response: AxiosResponse<any> = await Feedback.post({
                email: props.email,
                message: props.message,
                fullName: props.fullName
            });
            switch (response.status) {
                case 200:
                    setters.setData(true)
                    break;
                case 400:
                    setters.setErrors(response.data.data.errors)
                    break;
                default:
                    break;
            }
        }
    }
}