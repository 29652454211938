import React, {FC} from "react";
import './style.css'
import {hooks, ui} from "../../../ui";
import {actions, selectors} from "../../../../store/DealReducer";
import {InformationBlock} from "../../../ui/InformationBlock";
import {useHistory} from "react-router-dom";
import {useDispatch, useSelector} from "react-redux";
import {dealSectionImages} from "../../../../assets/images/landings/main";
import {CommissionGenerate} from "../../../Deal/commission";

const {
    dealBg
} = dealSectionImages
const {AlertContainer, Button, Input, Rub} = ui;

export const DealSection: FC = () => {

    /* hooks */
    const dispatch = useDispatch();
    const history = useHistory();
    const commission = useSelector(selectors.getCommission)

    /* state */
    const [sumError, setSumError] = React.useState('')
    const [emailError, setEmailError] = React.useState('')

    const sum = hooks.useField({placeholder: "Сумма", error: sumError, mask: "9".repeat(10)})
    const email = hooks.useField({placeholder: "Почта", error: emailError})

    const commissionValue = CommissionGenerate({
        commission: Number(commission),
        amount: Number(sum.value)
    })

    /* methods */
    const handleSetData = () => {
        if (sum.value && email.value) {
            dispatch(actions.setSum(Number(sum.value)))
            dispatch(actions.setSellerEmail(email.value))
            history.push('/deal/creating-deal')
        } else {
            setSumError(sum.value ? "" : "Введите сумму")
            setEmailError(email.value ? "" : "Введите E-mail")
        }
    }

    return (
        <section className='Grid DealSection'>
            <AlertContainer>
                <h3>Проведите безопасную сделку прямо сейчас</h3>
                <h5>Для расчета комиссии введите сумму.
                    Нажмите "Начать сделку" и заполните форму
                    для оформления безопасной сделки
                </h5>
                <div className="DealSection__form">
                    <Input label='Сумма' support={<Rub/>} {...sum}/>
                    <Input label='Почта' {...email}/>
                    <InformationBlock label='Комиссия за сделку:'>
                        {commissionValue.commission}
                    </InformationBlock>
                    <InformationBlock label='Продавец получает:'>
                        {commissionValue.sellerReceives}
                    </InformationBlock>
                </div>
                <Button onClick={handleSetData}>Начать сделку</Button>
            </AlertContainer>
            <img src={dealBg} className='DealSection__bg' alt='Волны'/>
        </section>
    )
}