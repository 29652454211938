import {
    DealReducerActionType,
    InitialStateType,
    SET_ACS_URL,
    SET_BUYER,
    SET_BUYER_EMAIL,
    SET_DEAL,
    SET_DEAL_ID,
    SET_ORDER_ID,
    SET_PA_REQ,
    SET_REQUEST_ID,
    SET_SELLER,
    SET_SELLER_EMAIL,
    SET_SUM
} from './types'
import {DealReducerThunk} from './modules/thunk';
import {
    setAcsUrl,
    setBuyer,
    setBuyerEmail,
    setCommission,
    setDeal,
    setDealId,
    setOrderId,
    setPaReq,
    setRequestId,
    setSeller,
    setSellerEmail,
    setSum
} from "./modules/actions";
import {
    getAcsUrl,
    getBuyer,
    getBuyerEmail,
    getCommission,
    getDeal,
    getDealId,
    getOrderId,
    getPaReq,
    getRequestId,
    getSeller,
    getSellerEmail,
    getSum
} from "./modules/selectors";

const InitialState: InitialStateType = {
    dealId: null,
    deal: {
        id: null,
        name: null,
        amount: null,
        description: null,
    },
    sum: null,
    sellerEmail: null,
    buyerEmail: null,
    seller: {
        id: null,
        lastName: null,
        firstName: null,
        middleName: null,
        email: null
    },
    buyer: {
        id: null,
        lastName: null,
        firstName: null,
        middleName: null,
        email: null
    },
    orderId: null,
    requestId: null,
    paReq: null,
    acsUrl:null,
    commission: 0.065
}

export const DealReducer = (state: InitialStateType = InitialState, action: DealReducerActionType): InitialStateType => {
    switch (action.type) {
        case SET_DEAL_ID:
            return {
                ...state,
                dealId: action.payload
            }
        case SET_SUM:
            return {
                ...state,
                sum: action.payload
            }
        case SET_SELLER:
            return {
                ...state,
                seller: action.payload
            }
        case SET_BUYER:
            return {
                ...state,
                buyer: action.payload
            }
        case SET_BUYER_EMAIL:
            return {
                ...state,
                buyerEmail: action.payload
            }
        case SET_SELLER_EMAIL:
            return {
                ...state,
                sellerEmail: action.payload
            }
        case SET_DEAL:
            return {
                ...state,
                deal: action.payload
            }
        case SET_ORDER_ID:
            return {
                ...state,
                orderId: action.payload
            }
        case SET_REQUEST_ID:
            return {
                ...state,
                requestId: action.payload
            }
        case SET_PA_REQ:
            return {
                ...state,
                paReq: action.payload
            }
        case SET_ACS_URL:
            return {
                ...state,
                acsUrl: action.payload
            }
        default:
            return state
    }
}


export const Thunk = DealReducerThunk;
export const actions = {
    setDealId,
    setDeal,
    setSum,
    setSeller,
    setBuyer,
    setBuyerEmail,
    setSellerEmail,
    setOrderId,
    setRequestId,
    setPaReq,
    setCommission,
    setAcsUrl
}
export const selectors = {
    getDealId,
    getDeal,
    getSum,
    getSeller,
    getBuyer,
    getSellerEmail,
    getBuyerEmail,
    getOrderId,
    getRequestId,
    getPaReq,
    getCommission,
    getAcsUrl
}

