import React, {FC} from 'react'
import {listData} from "./listData";
import './style.css'
import {informationImages} from "../../../../assets/images/landings/main";

const {
    informationListItem,
} = informationImages

export const FeaturesList: FC = () => {

    return (
        <ul className='FeaturesList'>
            {listData.map((item, index) => (
                <FeaturesListItem key={index} index={index}>{item}</FeaturesListItem>
            ))}
        </ul>
    )
}

const FeaturesListItem: FC<{ index: number }> = (props) => {
    const {
        children,
        index
    } = props

    return (
        <li className='FeaturesList__item'>
            <img className='FeaturesList__item__img' src={informationListItem}/>
            <span>{index + 1}</span>
            <div className='FeaturesList__item__text'>{children}</div>
            <img className='FeaturesList__item__img' src={informationListItem}/>
        </li>
    )
}