import React, {Fragment} from 'react'
import {AlertContainer} from "../../../ui/AlertContainer";
import {useHistory} from 'react-router-dom';
import {hooks, ui} from "../../../ui";

const {Button} = ui

export const SuccessStatus = () => {

    /* hooks */
    const history = useHistory();

    /* methods */
    const handleBackToDeal = () => history.push('/')

    return (
        <Fragment>
            <AlertContainer>
                Спасибо, Ваше сообщение будет рассмотрено в ближайшее время и вы получите ответ на почту.
            </AlertContainer>
            <Button onClick={handleBackToDeal}>На главную</Button>
        </Fragment>
    )
}