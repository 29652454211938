import React, {FC} from 'react'
import {informationImages} from "../../../../../assets/images/landings/main";
import {listData} from "./listData";
import './style.css'

const {
    informationListItem,
} = informationImages

export const InformationList: FC = () => {

    return (
        <ul className='DealInformation__list'>
            {listData.map((item, index) => (
                <InformationListItem key={index} index={index}>{item}</InformationListItem>
            ))}
        </ul>
    )
}

const InformationListItem: FC<{ index: number }> = (props) => {
    const {
        children,
        index
    } = props

    return (
        <li className='DealInformation__list__item'>
            <img className='DealInformation__list__item__img' src={informationListItem}/>
            <span>{index+1}</span>
            <div className='DealInformation__list__item__text'>{children}</div>
            <img className='DealInformation__list__item__img' src={informationListItem}/>
        </li>
    )
}