import React, {FC} from 'react'
import './style.css'
import {Common} from "../../assets/common";

export const Footer: FC = () => {

    const mail = `hello@${Common.Name}.ru`;
    const phone = '+7 (495) 746-09-70';

    return (
        <footer>
            <a href={`mailto:${mail}`}>{mail}</a>
            <a href={`tel:${phone}`}>{phone}</a>
        </footer>
    )
}