import React, {useEffect} from "react";
import {ui} from "../../ui";
import {paySystemsImages} from "../CardConnect/images";
import {Loading} from "../CardConnect/Statuses/loading";
import {Link, useLocation} from "react-router-dom";
import {actions} from "../../../store/DealReducer";
import {useDispatch} from "react-redux";
import {Support} from "../../../supports";

const {AlertContainer, Button} = ui;

export const GetStatus = () => {

    /* hooks */
    const dispatch = useDispatch()
    const location = useLocation()
    const dealId: number = new Support().getNumber(location.pathname)

    useEffect(() => {
        dispatch(actions.setDealId(dealId))
    }, [])

    return (
        <section className='CardConnect Grid DealPage'>
            <AlertContainer className='DealFieldsContainer--small'>
                <h3>Оплата картой</h3>
                <Loading/>
                <div className='CardConnect__pay-systems'>
                    <img src={paySystemsImages[0]} alt="visa"/>
                    <img src={paySystemsImages[1]} alt="mastercard"/>
                    <img src={paySystemsImages[2]} alt="mir"/>
                    <img src={paySystemsImages[3]} alt="pci"/>
                </div>
            </AlertContainer>
            <div className='CardConnect__confirm'>
                <Link to="/deal/creating-deal">
                    <Button variant='empty-blue'>Назад</Button>
                </Link>
                <Link to="/?support=true">
                    <Button variant='empty-black'>Техническая поддержка</Button>
                </Link>
            </div>
        </section>
    )
}


