type PropsType = {
    commission: number,
    amount: number
}

type ReturnedType = {
    sellerReceives: string
    buyerPays: string
    commission: string
}

export const CommissionGenerate = (props: PropsType): ReturnedType => {

    const {commission, amount} = props;

    const calculationCommission = (): number => {
        let commissionNew: number = (amount * commission)
        return commissionNew < 30 ? 30 : commissionNew
    }
    const negativeCheck = (): string => {
        const value: number = amount - calculationCommission()
        return value > 0 ? `${value.toFixed(2)} ₽` : "-"
    }
    return {
        sellerReceives: negativeCheck(),
        buyerPays: `${amount < 30 ? "-" : (amount.toFixed(2) + " ₽")} `,
        commission: `${amount < 30 ? "-" : (calculationCommission().toFixed(2) + ' ₽')}`
    }
}