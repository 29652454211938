import React, {FC} from "react";
import './style.css'
import {howWorksImages} from "../../../../../assets/images/landings/Pages/HowWorks";

const {
    umbrella
} = howWorksImages

export const ProtectionTime:FC = () => {

    return (
        <section className='ProtectionTime Grid'>
            <div className='ProtectionTime__content'>
                <div className='ProtectionTime__description'>
                    <h2 className='SectionTitle'>Период защиты сделки</h2>
                    <h4>
                        Период защиты сделки составляет 21 день с момента отправки товара,
                        если в условиях сделки не указан иной срок. Если в течение периода защиты сделки
                        Покупатель не осуществил действия «Принять Сделку» или «Открыть Претензию», сделка считается завершенной,
                        деньги переводятся лицу, выступающим в качестве Продавца.
                    </h4>
                </div>
                <img src={umbrella}/>
            </div>
        </section>
    )
}