import React, {FC, Fragment} from 'react'
import './style.css'
import {Feedback} from "../../uiSections/Feedback";
import {hooks} from "../../../ui";

export const About: FC = () => {

    hooks.useScrollToTop()
    return (
        <Fragment>
            <section className='About Grid Page'>
                <h2 className='PageTitle'>О компании</h2>
                <p> Наша компания разрабатывает сервисы для людей,
                    занимающихся бизнесом. Наша первостепенная задача - для клиента,
                    а также бережное и заботливое отношение к их бизнесу. Если наше предложение Вас заинтересовало - Вы
                    всегда можете
                    связаться с нашим менеджером и тогда мы сделаем Ваших клиентов счастливыми и позитивными.
                    Нашими партнерами являются самые надежные банки, работающие и находящиеся по всему миру. Мы
                    предлагаем стать
                    Вам нашим клиентом. Станьте нашим постоянным клиентом и думайте о надежной безопасности и
                    сохранности своего бизнеса.
                </p>
            </section>
            <Feedback/>
        </Fragment>
    )
}