import React from 'react'
import './style.css'
import {NoteElement} from "../../../uiSections/NoteElement";
import {commonImages} from "../../../../../assets/images/common";

const {
    walletIcon,
    cardIcon
} = commonImages.statusIcons

export const AdditionalCommission = () => {

    return (
        <section className='AdditionalCommission Grid'>
            <h2 className='SectionTitle'>Дополнительные комиссии</h2>
            <div className='AdditionalCommission__content'>

                <div className='AdditionalCommission__table'>
                    <NoteElement img={cardIcon} title='Получение оплаты от покупателя'>
                        Потребитель может заплатить за сделку посредством банковской карты или же через систему электронного платежа.
                    </NoteElement>
                    <ul>
                        <li>Комиссия при оплате через банковскую карту и другие способы</li>
                    </ul>
                    <div className='AdditionalCommission__table__columns'>
                        <div className='AdditionalCommission__table__columns__line'/>

                        <b>Проведение платежа</b>
                        <b>Срок зачисления*</b>
                        <b>Комиссия за перевод**</b>

                        <div>Visa, MasterCard, МИР</div>
                        <div>Online</div>
                        <div>3,3%</div>

                        <div>WebMoney</div>
                        <div>Online</div>
                        <div>1,5%</div>

                        <div>Qiwi</div>
                        <div>Online</div>
                        <div>6%</div>

                        <div>Яндекс.Деньги	</div>
                        <div>Online</div>
                        <div>6%</div>

                        <div className='AdditionalCommission__table__columns__line'/>
                    </div>
                    <div className='AdditionalCommission__table__reference'>
                        <p>
                            <span className='blue-star'>*&nbsp;</span>
                            В большинстве случаев
                            перевод осуществляется моментально, но время транзакции может
                            быть увеличено банком-эквайером
                        </p>
                    </div>
                    <div className='AdditionalCommission__table__reference'>
                        <p>
                            <span className='blue-star'>**&nbsp;</span>
                            Комиссии приведены справочно и могут быть изменены
                        </p>
                    </div>
                </div>

                <div className='AdditionalCommission__table'>
                    <NoteElement img={walletIcon} title='Перевод денег продавцу'>
                        Продавец должен указать реквизиты для получения платежа, при выводе взимается комиссия
                    </NoteElement>
                    <ul>
                        <li>
                            <p>
                                Вывод на банковскую карту, эмитентом которой является банковское учреждение, зарегистрированное и
                                действующее в соответствии с законодательством иного государства, - 3,5%,
                                но не менее 190 рублей РФ
                                <span className='blue-star'>&nbsp;***</span>
                            </p>
                        </li>
                        <li>
                            <p>
                                Вывод на банковскую карту, эмитентом которой является банковское учреждение,
                                зарегистрированное и действующее в соответствии с законодательством РФ,
                                - 2%, но не менее 30 рублей РФ
                                <span className='blue-star'>&nbsp;***</span>
                            </p>
                        </li>
                        <li>
                            <p>
                                Вывод на банковскую карту, эмитентом которой является
                                банковское учреждение, зарегистрированное
                                и действующее в соответствии с законодательством Украины,
                                - 3,5%, но не менее 190 рублей РФ
                                <span className='blue-star'>&nbsp;***</span>
                            </p>
                        </li>
                        <li>
                            <p>
                                Вывод в систему Яндекс.Деньги - 2,5%
                                <span className='blue-star'>&nbsp;***</span>
                            </p>
                        </li>
                        <li>
                            <p>
                                Вывод в систему QIWI Кошелек - 2,5%
                                <span className='blue-star'>&nbsp;***</span>
                            </p>
                        </li>
                        <li>
                            <p>
                                Вывод в систему WebMoney Transfer - 3,3%
                                <span className='blue-star'>&nbsp;***</span>
                            </p>
                        </li>
                        <li>
                            <p>
                                Вывод в систему оператора связи ПАО «Вымпелком», ПАО «МТС», ПАО «МегаФон» - 2%
                                <span className='blue-star'>&nbsp;***</span>
                            </p>
                        </li>
                    </ul>
                    <div className='AdditionalCommission__table__reference'>
                        <p>
                            <span className='blue-star'>***&nbsp;</span>
                            Комиссии приведены справочно и могут быть изменены
                        </p>
                    </div>
                </div>

                <div className='AdditionalCommission__line'/>
            </div>
        </section>
    )
}