import React, {FC, Fragment, useEffect} from "react";
import './style.css'
import {StartSection} from "./StartSection";
import {DealSection} from "./DealSection";
import {DealInformation} from "./DealInformation";
import {ServiceBenefits} from "./ServiceBenefits";
import {ServiceAudience} from "./ServiceAudience";
import {FinalSection} from "./FinalSection";
import {useLocation} from "react-router-dom";
import {Support} from "../../../supports";

const support = new Support()

export const Main: FC = () => {

    const location = useLocation()
    const params: any = support.queryParams(location.search);

    useEffect(() => {
        const feedback = window.document.querySelector('#Feedback')
        if (feedback && params.support) {
            feedback.scrollIntoView({behavior: 'smooth'});
        }
    }, [params])

    return (
        <Fragment>
            <StartSection/>
            <DealSection/>
            <DealInformation/>
            <ServiceBenefits/>
            <ServiceAudience/>
            <FinalSection/>
        </Fragment>
    )
}