import React, {FC, Fragment} from "react";
import './style.css'
import {Feedback} from "../../uiSections/Feedback";
import {StartSectionWave} from "../../uiSections/StartSectionWave";
import {Stages} from "./Stages";
import {InfoList} from "./InfoList";
import {ProtectionTime} from "./ProtectionTime";
import {hooks} from "../../../ui";


export const HowWorks: FC = () => {

    hooks.useScrollToTop()

    return (
        <Fragment>
            <section className='HowWorks'>
                <StartSectionWave title='Как работает безопасная сделка?'>
                    Мы предлагаем сервис по защите торговых сделок в интернете,
                    который гарантирует выполнение обязательств и снижает риски мошенничества и подлога.
                </StartSectionWave>
                <InfoList/>
                <Stages/>
                <ProtectionTime/>
            </section>
            <Feedback/>
        </Fragment>
    );
}