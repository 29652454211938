import {methods} from './methods';
import {instance} from './axios';
import {
    IClient,
    IClientUpdate,
    IDeal,
    IDealApprove,
    IDealGet,
    IDealStart,
    ICallback,
    IFeedback,
    IClientCreate
} from './types';
import {Support} from "../supports";

const support = new Support()


const headersForPost = {
    headers: {
        'Content-Type': 'application/x-www-form-urlencoded',
    }
}

export const Client = {
    get: async (props: IClient) => {
        return await methods.Responses(
            instance.get(`/client?email=${props.email}`)
        )
    },
    create: async (props: IClientCreate) => {
        console.log(props)
        return await methods.Responses(
            instance.post('/client', {
                last_name: props.lastName,
                first_name: props.firstName,
                middle_name: props.middleName,
                email: props.email
            })
        )
    },
    update: async (props: IClientUpdate) => {
        return await methods.Responses(
            instance.put(`/client/${props.clientId}`, {
                last_name: props.lastName,
                first_name: props.firstName,
                middle_name: props.middleName,
                email: props.email,
                card_number: support.clear(String(props.cardNumber)),
            })
        )
    },
}


export const Deal = {
    create: async (props: IDeal) => {
        return await methods.Responses(
            instance.post('/deal', {
                name: props.name,
                amount: props.amount,
                description: props.description,
                seller_id: props.sellerId,
                buyer_id: props.buyerId
            }, headersForPost)
        )
    },
    get: async (props: IDealGet) => {
        return await methods.Responses(
            instance.get(`/deal/${props.dealId}`)
        )
    },
    start: async (props: IDealStart) => {
        return await methods.Responses(
            instance.put(`/deal/${props.dealId}/start`, {
                card_number: props.cardNumber,
                exp_year: props.expYear,
                exp_month: props.expMonth,
                cvv: props.cvv,
                card_holder: props.cardHolder
            })
        )
    },
    approve: async (props: IDealApprove) => {
        return await methods.Responses(
            instance.put(`/deal/${props.dealId}/approve`, {
                pa_res: props.paRes
            })
        )
    },
}

export const Callback = {
    post: async (props: ICallback) => {
        return await methods.Responses(
            instance.post('/callback', {
                OrderInfo: {
                    ExtId: props.OrderInfo.ExtId,
                    OrderId: props.OrderInfo.OrderId,
                    State: props.OrderInfo.State,
                    StateDescription: props.OrderInfo.StateDescription,
                    Type: props.OrderInfo.Type,
                    Amount: props.OrderInfo.Amount,
                    ExecDateTime: props.OrderInfo.ExecDateTime
                }
            })
        )
    },
}


export const Feedback = {
    post: async (props: IFeedback) => {
        return await methods.Responses(
            instance.post('/feedback', {
                email: props.email,
                message: props.message,
                full_name: props.fullName
            })
        )
    },
}