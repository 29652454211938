import React, {Fragment, useEffect, useState} from 'react'
import './style.css'
import {selectors, Thunk} from "../../../store/DealReducer";
import {methods} from "../../../api/methods";
import {hooks, ui} from "../../ui";
import {paySystemsImages} from "./images";
import {useDispatch, useSelector} from "react-redux";
import {Loading} from "./Statuses/loading";
import {Success} from "./Statuses/Success";
import {Error} from "./Statuses/Error";
import {Link, Route, useLocation} from 'react-router-dom'
import {SubmitData} from './submitData';


const {BankCard, AlertContainer, Button} = ui

export const CardConnect = () => {

    /* hooks */
    hooks.useScrollToTop()
    const dispatch = useDispatch();
    const location = useLocation();
    const dealId = useSelector(selectors.getDealId)
    const amount = useSelector(selectors.getSum)
    const requestId = useSelector(selectors.getRequestId)
    const paReq = useSelector(selectors.getPaReq)
    const acsUrl = useSelector(selectors.getAcsUrl)


    const [getters, setters] = methods.useSetters();
    const bankCard = hooks.useBankCardField();

    const [requiredCardField, setRequiredCardField] = useState<boolean>(false)
    const [errorsObject, setErrorsObject] = useState<any>({})


    /* mapping Errors */
    useEffect(() => {
        setErrorsObject(methods.MappingErrors(getters.errors))
    }, [getters.errors])

    /* DAL */
    const thunk = Thunk(setters);
    const handlePicCard = () => {
        if (
            bankCard.cardNumber.value.length === 16
            && bankCard.cardDate.value.length === 4
            && bankCard.cardCvv.value.length === 3
        ) {
            const number = Number(bankCard.cardNumber.value)
            const year = (Number(String(new Date().getFullYear()).slice(0, 2) + bankCard.cardDate.value.slice(2)))
            const month = Number(bankCard.cardDate.value.slice(0, 2))
            const cvv = String(bankCard.cardCvv.value)
            dispatch(thunk.startDeal({
                dealId: Number(dealId),
                cvv: cvv,
                cardNumber: number,
                expYear: year,
                expMonth: month,
                cardHolder: null,
            }))
        } else {
            setRequiredCardField(true)
        }
    };

    /* redirect on 3ds */
    useEffect(() => {
        if (
            dealId
            && requestId
            && paReq
            && acsUrl
        ) {
            SubmitData({
                dealId: dealId,
                requestId: requestId,
                paReq: paReq,
                acsUrl: acsUrl,
            })
        }
    }, [requestId, paReq, acsUrl])


    const ButtonRedirect = () => {
        switch (location.pathname) {
            case "/deal/card-connect/success":
                return <Link to="/"><Button variant='empty-blue'>На главную</Button></Link>
            default:
                return <Link to="/deal/creating-deal"><Button variant='empty-blue'>Назад</Button></Link>
        }
    }


    return (
        <section className='CardConnect Grid DealPage'>
            <AlertContainer className='DealFieldsContainer--small'>
                <h3>Оплата картой</h3>
                <Route exact path='/deal/card-connect'>
                    <Fragment>
                        <BankCard
                            errors={{
                                card_number: errorsObject.card_number,
                                exp_month: errorsObject.exp_month,
                                exp_year: errorsObject.exp_year,
                                cvv: errorsObject.cvv,
                            }}
                            required={requiredCardField}
                            {...bankCard}
                        />
                        <span>{Number(amount).toFixed(2)} ₽</span>
                        <Button onClick={handlePicCard}>Оплатить</Button>
                    </Fragment>
                </Route>
                <Route path='/deal/card-connect/loading'>
                    <Loading/>
                </Route>
                <Route path='/deal/card-connect/success'>
                    <Success/>
                </Route>
                <Route path='/deal/card-connect/error'>
                    <Error/>
                </Route>
                <div className='CardConnect__pay-systems'>
                    <img src={paySystemsImages[0]} alt="visa"/>
                    <img src={paySystemsImages[1]} alt="mastercard"/>
                    <img src={paySystemsImages[2]} alt="mir"/>
                    <img src={paySystemsImages[3]} alt="pci"/>
                </div>
            </AlertContainer>
            <div className='CardConnect__confirm'>
                <ButtonRedirect/>
                <Link to="/?support=true">
                    <Button variant='empty-black'>Техническая поддержка</Button>
                </Link>
            </div>
        </section>
    )
}