import React, {FC} from "react";
import './style.css'
import {howWorksImages} from "../../../../../assets/images/landings/Pages/HowWorks";
import {stageStatusesData} from "./stageStatusesData";
import {FeaturesList} from "../../../uiSections/FeaturesList";
import {NoteElement} from "../../../uiSections/NoteElement";

const {
    arrow
} = howWorksImages

export const Stages:FC = () => {

    return (
        <section className='Stages Grid'>
            <div className='Stages__header'>
                <div className='Stages__description'>
                    <h2 className='SectionTitle'>Все этапы сделки</h2>
                    <h4>
                        Все защищенные сделки имеют определенные статусы
                        в зависимости от этапа. Статусы управляются пользователями
                        или сервисом, согласно заданных условий.
                    </h4>
                </div>
                <img src={arrow}/>
            </div>
            <div className='Stages_statuses'>
                {stageStatusesData.map((item, index) => (
                    <NoteElement img={item.img} title={item.title}>
                        {item.text}
                    </NoteElement>
                ))}
                <div className='Stages_statuses__vertical-line'/>
            </div>
        </section>
    )
}