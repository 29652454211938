import React, {FC, useEffect, useState} from "react";
import './style.css'
import classNames from 'classnames'
import {locationDependencies} from "./locationDependencies";
import {Link, useHistory, useLocation} from 'react-router-dom'
import {Button} from "../ui/Button";
import {Logo} from "./Logo";

export const Header: FC = () => {

    /* hooks */
    const location = useLocation()
    const history = useHistory()


    /* state */
    const [isNavOpened, setIsNavOpened] = useState(false)
    const [blackLogo, setBlackLogo] = useState<boolean>(false)
    const [isDropdownOpened, setIsDropdownOpened] = useState(false)

    /* handlers */
    const dropdownToggle = () => setIsDropdownOpened(!isDropdownOpened)
    const navToggle = () => setIsNavOpened(!isNavOpened)
    const handleToCreateDeal = () => history.push("/deal/creating-deal")

    /* effects */
    useEffect(() => {
        setBlackLogo(
            !locationDependencies.filter(path => path === location.pathname).length
        )
    }, [location])


    /* view methods */
    const getCurrentPage = () => {
        switch (location.pathname) {
            case '/conditions':
                return 'Оплата и условия'
            case '/service-rules':
                return 'Правила сервиса'
            case '/how-works':
                return 'Как это работает'
            case '/solving-conflicts':
                return 'Решение споров'
            default:
                return 'Как это работает'
        }
    }

    /* classes */
    const classes = classNames({
        'Header': true,
        'Header--black': blackLogo,
    })
    const dropDownClasses = classNames({
        'Header__nav-item': true,
        'Header__dropdown__wrapper': true,
        'Header__dropdown--active': isDropdownOpened
    })

    return (
        <header className={classes}>
            <Link to='/'>
                <Logo black={blackLogo}/>
            </Link>
            <nav className='Header__desktop-nav'>
                <Link className='Header__nav-item' to='/about'>О нас</Link>
                <div className={dropDownClasses} onClick={dropdownToggle}>
                    <span>{getCurrentPage()}</span>
                    <div className='Header__dropdown'>
                        <Link to='/how-works'>Как это работает</Link>
                        <Link to='/conditions'>Оплата и условия</Link>
                        <Link to='/solving-conflicts'>Решение споров</Link>
                        <Link to='/service-rules'>Правила сервиса</Link>
                    </div>
                </div>
                <Button
                    className='Header__nav-item'
                    onClick={handleToCreateDeal}
                    variant='empty-blue'>Перевести</Button>
            </nav>

            <div className='Header__burger' onClick={navToggle}/>
            {isNavOpened && (
                <nav className='Header__mobile-nav'>
                    <Link className='Header__nav-item' to='/about' onClick={navToggle}>О нас</Link>
                    <div className='Header__nav-item'>
                        <div className={dropDownClasses} onClick={dropdownToggle}>
                            <span>{getCurrentPage()}</span>
                            <div className='Header__dropdown'>
                                <Link to='/how-works' onClick={navToggle}>Как это работает</Link>
                                <Link to='/conditions' onClick={navToggle}>Оплата и условия</Link>
                                <Link to='/solving-conflicts' onClick={navToggle}>Решение споров</Link>
                                <Link to='/service-rules' onClick={navToggle}>Правила сервиса</Link>
                            </div>
                        </div>
                    </div>
                    <Link to="/deal/creating-deal">
                        <Button className='Header__nav-item' variant='empty-blue'>Перевести</Button>
                    </Link>
                </nav>
            )}
        </header>
    )
}