import React, {FC} from "react";
import './style.css'
import {audienceCardsData} from "./AudienceCard/audienceCardsData";
import {AudienceCard} from "./AudienceCard";
import {SliderHook} from "./SliderHook";


export const ServiceAudience: FC = () => {

    const {
        bind,
        slides,
        slidePrev,
        slideNext,
        tempSlide
    } = SliderHook()

    const slideDetectorClasses = (index: number) => {
        return index == tempSlide ? 'slide-detector--active' : ''
    }

    return (
        <section className='Main__ServiceAudience Grid'>
            <h2 className='SectionTitle'>Кому полезен наш сервис?</h2>
            <div {...bind()} className='Main__ServiceAudience__list'>
                {audienceCardsData.map((item, index) => (
                    <AudienceCard
                        refArray={slides}
                        key={index}
                        index={index}
                        {...item}
                    />
                ))}
                <div className='Main__ServiceAudience__slider-controller' onClick={slidePrev}/>
                <div className='Main__ServiceAudience__slider-controller' onClick={slideNext}/>
            </div>
            <div className='Main__ServiceAudience__slide-detector'>
                {slides.current.map((_, index) =>
                    <span
                        key={index}
                        className={slideDetectorClasses(index)}
                    />
                )}
            </div>
        </section>
    )
}