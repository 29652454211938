import React, {FC} from "react";
import './style.css'
import InputMask from 'react-input-mask';
import classNames from 'classnames'
import {PropsType} from "./types";

export const Input: FC<PropsType> = (props) => {

    const {
        mask,
        error,
        value,
        label,
        support,
        onSupport,
        ...all
    } = props;


    /* classes */
    const classes = classNames({
        'Input--error': !!error,
        'Input--filled': !!value,
        'Input': true,
    });

    /* all attributes & props */
    const attrs = {
        id: 'input',
        value: value,
        mask: mask || '',
        maskChar: null,
        ...all,
    }

    return (
        <div className={classes}>
            <InputMask {...attrs}/>
            {label && <label className='Input__label'>{label}</label>}
            {error && <span className='Input__error'>{error}</span>}
            {support && <button onClick={onSupport} className='Input__support'>{support}</button>}
        </div>
    )
}