import React, {Fragment} from 'react'
import './style.css'
import {hooks, ui} from "../../ui";
import {selectors} from "../../../store/DealReducer";
import {useSelector} from "react-redux";
import {ButtonWithAlert} from "../../ui/ButtonWithAlert";
import {Link, useHistory} from "react-router-dom";


const {
    InformationBlock,
    AlertContainer,
    Button,
} = ui;

export const InformationOfDeal = () => {


    /* hooks */
    hooks.useScrollToTop()
    const history = useHistory()
    const sumFromStore = useSelector(selectors.getSum)
    const sellerFromStore = useSelector(selectors.getSeller)
    const buyerFromStore = useSelector(selectors.getBuyer)
    const dealFromStore = useSelector(selectors.getDeal)

    /* state */

    /* vars */
    const BuyerFullName = {
        email: buyerFromStore?.email || "",
        lastName: buyerFromStore?.lastName || "",
        firstName: buyerFromStore?.firstName || "",
        middleName: buyerFromStore?.middleName || "",
        isFio: (
            buyerFromStore?.lastName
            && buyerFromStore?.firstName
            && buyerFromStore?.middleName
        )
    }

    const SellerFullName = {
        email: sellerFromStore?.email || "",
        lastName: sellerFromStore?.lastName || "",
        firstName: sellerFromStore?.firstName || "",
        middleName: sellerFromStore?.middleName || "",
        isFio: (
            sellerFromStore?.lastName
            && sellerFromStore?.firstName
            && sellerFromStore?.middleName
        )
    }

    const handleSendArbitration = () => history.push('/deal/arbitration/')
    const handleCardConnect = () => history.push('/deal/card-connect')


    return (
        <section className='DealPage InformationOfDeal Grid'>
            <h2 className='DealPageTitle'>Информация по сделке</h2>
            <AlertContainer>
                <h3>{dealFromStore?.name || ""}</h3>
                <h5>{dealFromStore?.description || ""}</h5>
            </AlertContainer>
            <div className='DealFieldsContainer--small InformationOfDeal__info'>
                <div className='InformationOfDeal__info__user'>
                    <div><span>Покупатель</span></div>
                    <InformationBlock label='ФИО:'>
                        {BuyerFullName.isFio
                            ? `${BuyerFullName.middleName} ${BuyerFullName.firstName} ${BuyerFullName.lastName}`
                            : `Не заполнено! Свяжитесь с покупателем и попросите заполнить данные.`
                        }
                    </InformationBlock>
                    <InformationBlock label='E-mail:'>
                        {BuyerFullName.email}
                    </InformationBlock>
                </div>
                <div className='InformationOfDeal__info__user'>
                    <div style={{display: "flex"}}>
                        <span>Продавец</span>
                    </div>
                    <div className='InformationOfDeal__info__merchant__blocked'>
                        <InformationBlock label='ФИО:'>
                            {SellerFullName.isFio
                                ? `${SellerFullName.middleName} ${SellerFullName.firstName} ${SellerFullName.lastName}`
                                : `Не заполнено! Свяжитесь с покупателем и попросите заполнить данные.`
                            }
                        </InformationBlock>
                        <InformationBlock label='E-mail:'>
                            {SellerFullName.email}
                        </InformationBlock>
                    </div>
                </div>
            </div>
            <div className='DealFieldsContainer--small InformationOfDeal__confirm'>
                <h4>Сумма для получения:<br/>{sumFromStore} ₽</h4>
                <ButtonWithAlert variant={"green"} onClick={handleCardConnect} alertContent={(<Fragment>
                    <p>Нажимая кнопку "Привязать карту" я соглашаюсь с условиями<Link to='/service-rules'> оферты</Link>
                    </p>
                </Fragment>)}>
                    Привязать карту
                </ButtonWithAlert>
                <Button variant='empty-blue' onClick={handleSendArbitration}>Написать в арбитраж</Button>
            </div>

            <div className='InformationOfDeal__deal-events'>
                <h3>События по сделке</h3>
                <div className='InformationOfDeal__deal-events__event'>
                    <b>Дата</b>
                    <div>25.09.2020, 11:57</div>
                </div>
                <div className='InformationOfDeal__deal-events__event'>
                    <b>Описание</b>
                    <div>Сделка успешно создана.</div>
                </div>
            </div>
        </section>
    )
}