import React, {FC, useEffect} from 'react'
import './style.css'
import {useDispatch, useSelector} from "react-redux";
import {selectors, Thunk} from "../../../../store/DealReducer";
import {methods} from "../../../../api/methods";


export const Loading: FC = () => {

    /* hooks */
    const dispatch = useDispatch();
    const dealId = useSelector(selectors.getDealId);
    const [getters, setters] = methods.useSetters({initialLoading: true});


    /* DAL */
    const thunk = Thunk(setters);

    useEffect(() => {
        let timer: any = null;
        if (getters.loading && dealId) {
            timer = setInterval(() => {
                dispatch(thunk.getDealStatus({dealId: Number(dealId)}))
            }, 3000)
        }
        return () => clearInterval(timer)
    }, [getters])


    return (
        <div className='Status'>
            <div className="lds-default">
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
                <div/>
            </div>
            <div>Пожалуйста,<br/> подождите</div>
        </div>
    )
}