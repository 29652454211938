import {commonImages} from "../../../../../assets/images/common";

const {
    arbitrationIcon,
    messageIcon,
    expectingPaymentIcon,
    paidIcon,
    closedIcon,
    deliveredIcon,
    deliveryIcon,
    expectingConfirmIcon
} = commonImages.statusIcons

export const stageStatusesData = [
    {
        img: expectingConfirmIcon,
        title: 'Статус «Ожидает подтверждения»',
        text: 'Статус, образовывающийся после того, как Покупатель или Продавец создали сделку. В этом статусе Продавец или Покупатель должны подтвердить сделку в личном кабинете. В условиях данной сделки есть возможность указать подробные условия, являющиеся обязательными к исполнению одной или обеими сторонами условия доставки, этапы оплаты, качество товаров/услуг, срок проверки или гарантийные обязательства.'
    },
    {
        img: expectingPaymentIcon,
        title: 'Статус «Ожидается оплата»',
        text: 'После подтверждения оплаты, которая была совершена обеими сторонами, сделка переходит в статус «Ожидается оплата». Для совершения каких-либо дальнейших действий Покупателю требуется провести оплату по действующей сделки с  Продавцом. Оплата совершается банковским переводом, банковской картой или электронными деньгами на сайте.'
    },
    {
        img: paidIcon,
        title: 'Статус «Оплачена»',
        text: 'На действующем этапе Продавец обязуется передать товар в доставку или передать услугу с оговоренным и согласованным с Покупателем способом. После отправки товара загрузите скан копии квитанции и код, необходимый для отслеживания доставки в личном кабинете.'
    },
    {
        img: deliveryIcon,
        title: 'Статус «Доставка»',
        text: 'Когда сделка находится в статусе «Доставка», Покупатель может совершить действия: «Принять заказ», «Открыть претензию» или «Продлить срок защиты». Если Покупатель подтверждает, что товар получен и соответствует оговоренным с Продавцом условиям, сделка подлежит закрытию. Если товар был не доставлен или он не соответствует требованиям и заказу покупателя выставляет претензию продавцу. Тогда деньги сохраняются в нашем сервисе до момента разрешения спора.'
    },
    {
        img: deliveredIcon,
        title: 'Статус «Доставлено»',
        text: 'Статус означает, что товар был доставлен Покупателю.'
    },
    {
        img: messageIcon,
        title: 'Статус «Претензия»',
        text: 'Покупатель может на выбор: «Закрыть претензию», «Запросить возврат» либо «Запросить частичный возврат».'
    },
    {
        img: arbitrationIcon,
        title: 'Статус «Арбитраж»',
        text: 'Если за период из 7 дней стороны не пришли к согласованности, они имеют право прибегнуть к помощи опытных и проверенных арбитражных консультантов, которые изучат суть спора и после предложат обеим сторонам имеющиеся компромиссные варианты'
    },
    {
        img: closedIcon,
        title: 'Статус «Закрыта»',
        text: 'Статус «Закрыта» означает то, что сделка была успешно завершена. В случае выполнения обязательств перед покупателем наш сервис переводит денежные средства на указанные реквизиты продавца, при выявленном ненадлежащем исполнении условий сделки - возвращает покупателю. Сделка закрывается.'
    }

]