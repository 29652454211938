import {serviceAudienceImages} from "../../../../../assets/images/landings/main";

const {
    serviceAudienceImage1,
    serviceAudienceImage2,
    serviceAudienceImage3,
    serviceAudienceImage4
} = serviceAudienceImages

export const audienceCardsData = [
    {
        title: 'Продавцам',
        text: 'Если вы продаёте товары и услуги в интернете, то воспользуйтесь сервисом безопасной сделки, чтобы получать 100% предоплату',
        BgImage: serviceAudienceImage1
    },
    {
        title: 'Покупателям',
        text: 'Если вы хотите купить товар или услугу через интернет, но не до конца доверяете продавцу - создайте безопасную сделку!',
        BgImage: serviceAudienceImage2
    },
    {
        title: 'Торговым площадкам',
        text: 'Благодаря подключенной системе защиты сделки ваши покупатели будут больше вам доверять!',
        BgImage: serviceAudienceImage3
    },
    {
        title: 'E-commerce',
        text: 'Подключив систему защищенной сделки, вы привлечёте новых клиентов',
        BgImage: serviceAudienceImage4
    },

]