import {StateType} from "../../types";

export const getDealId = (state: StateType) => {
    return state.DealReducer.dealId
}

export const getSum = (state: StateType) => {
    return state.DealReducer.sum
}

export const getSeller = (state: StateType) => {
    return state.DealReducer.seller
}

export const getBuyer = (state: StateType) => {
    return state.DealReducer.buyer
}

export const getSellerEmail = (state: StateType) => {
    return state.DealReducer.sellerEmail
};

export const getBuyerEmail = (state: StateType) => {
    return state.DealReducer.buyerEmail
};

export const getDeal = (state: StateType) => {
    return state.DealReducer.deal
};
export const getOrderId = (state: StateType) => {
    return state.DealReducer.orderId
};

export const getRequestId = (state: StateType) => {
    return state.DealReducer.requestId
};

export const getPaReq = (state: StateType) => {
    return state.DealReducer.paReq
};
export const getCommission = (state: StateType) => {
    return state.DealReducer.commission
};
export const getAcsUrl = (state: StateType) => {
    return state.DealReducer.acsUrl
};