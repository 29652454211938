import React, {FC} from "react";
import './style.css'
import {serviceBenefitsImages} from "../../../../assets/images/landings/main";

const {rocket} = serviceBenefitsImages;

export const ServiceBenefits: FC = () => {

    return (
        <section className='Main__ServiceBenefits Grid'>
            <div className='SectionTitle'>Преимущества нашего сервиса</div>
            <img src={rocket} alt="Изображение ракеты"/>
            <ul className='Main__ServiceBenefits__benefits'>
                <li>Независимая третья сторона</li>
                <li>Финансовые транзакции через счет в банке</li>
                <li>Уверенность, спокойствие и безопасность</li>

                <li>Простое урегулирование спорных ситуаций между продавцом и покупателем</li>
                <li>Гарантия выполнения обязательств (покупатель оплатит, а продавец выполнит условия)</li>
                <li>Возможность проводить защищенные платежи между физическими лицами</li>

                <li>Снижение рисков мошенничества и подлога при покупках в Интернете</li>
                <li>Защиту сделок купли-продажи 24/7 без похода к юристу и в банк</li>
                <li>Эксперты в области финансовых технологий</li>
            </ul>
        </section>
    )
}