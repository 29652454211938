import React, {FC} from 'react'
import success from './success.svg'

export const Success: FC = () => {

    return (
        <div className='Status'>
            <img src={success} alt="#"/>
            <div> Оплата произведена<br/>успешно!</div>
        </div>
    )
}