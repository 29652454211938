import React, {FC, Fragment} from "react";
import './style.css'
import {Feedback} from "../../uiSections/Feedback";
import {StartSectionWave} from "../../uiSections/StartSectionWave";
import {Commission} from "./Commission";
import {AdditionalCommission} from "./AdditionalCommission";
import {hooks} from "../../../ui";


export const PaymentAndConditions: FC = () => {

    hooks.useScrollToTop()

    return (
        <Fragment>
            <section className='ServiceRules'>
                <StartSectionWave title='Оплата и условия'>
                    Мы предлагаем сервис для защиты онлайн сделок купли-продажи для физических и юридических лиц
                </StartSectionWave>
                <Commission/>
                <AdditionalCommission/>
            </section>
            <Feedback/>
        </Fragment>
    )
}