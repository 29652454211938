import React, {FC} from "react";
import './style.css'
import {FeaturesList} from "../../../uiSections/FeaturesList";



export const InfoList:FC = () => {

    return (
        <section className='InfoList Grid'>
            <h2 className='SectionTitle'>Как происходит<br/>защита сделки?</h2>
            <FeaturesList/>
        </section>
    )
}