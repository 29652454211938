import React, {FC, useContext} from "react";
import './style.css'
import {startSectionImages} from "../../../../assets/images/landings/main";
import {MediaQueryContext} from "../../../../contexts/MediaQueryContext";
import {ui} from "../../../ui";
import {useHistory} from "react-router-dom";

const {Button} = ui
const {
    FeatureIcon1,
    FeatureIcon2,
    FeatureIcon3,
    startBg,
} = startSectionImages

export const StartSection: FC = () => {

    /* hooks */
    const history = useHistory()
    const media = useContext(MediaQueryContext)

    /* methods */
    const handleToDeal = () => history.push("/deal/creating-deal")

    return (
        <section className='MainLanding__StartSection Grid'>
            <div className='left-content'>
                <div className='MainLanding__StartSection__features'>
                    <div className='MainLanding__StartSection__feature__item'>
                        <div className='MainLanding__StartSection__feature-line'/>
                        <img src={FeatureIcon1}/>
                        <p>Быстро и легко</p>
                        <div className='MainLanding__StartSection__feature-line'/>
                    </div>
                    <div className='MainLanding__StartSection__feature__item'>
                        <div className='MainLanding__StartSection__feature-line'/>
                        <img src={FeatureIcon2}/>
                        <p>Круглосуточная поддержка</p>
                        <div className='MainLanding__StartSection__feature-line'/>
                    </div>
                    <div className='MainLanding__StartSection__feature__item'>
                        <div className='MainLanding__StartSection__feature-line'/>
                        <img src={FeatureIcon3}/>
                        <p>API для партнеров</p>
                        <div className='MainLanding__StartSection__feature-line'/>
                    </div>
                </div>
                <div className='SectionTitle'>
                    Сервис для проведения
                    безопасных сделок
                    в Интернете
                </div>
                <div className='MainLanding__StartSection__Buttons'>
                    <Button onClick={handleToDeal}>Безопасно купить</Button>
                    <Button onClick={handleToDeal}>Безопасно продать</Button>
                </div>
            </div>
            <img src={startBg[media]} className='MainLanding__StartSection__bg' alt='Картинка смартфона'/>
        </section>
    )
}