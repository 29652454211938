import React, {FC} from 'react'
import errorIcon from './Error.svg'

export const Error: FC = () => {

    return (
        <div className='Status'>
            <img src={errorIcon} alt='#'/>
            <div>Оплата не удалась.<br/> Введите другую карту.</div>
        </div>
    )
}