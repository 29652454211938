import React, {Fragment, useEffect, useState} from 'react'
import './style.css'
import './assetsCss/infoCard.css'
import {informationImages} from "../../../assets/images/landings/main";
import {hooks, ui} from "../../ui";
import {selectors, Thunk} from "../../../store/DealReducer";
import {methods} from "../../../api/methods";
import {useDispatch, useSelector} from "react-redux";
import {ButtonWithAlert} from "../../ui/ButtonWithAlert";
import {Link} from "react-router-dom";
import {IErrorsFront} from "../../../api/types";
import {CommissionGenerate} from "../commission";

const {informationListItem} = informationImages
const {
    InformationBlock,
    AlertContainer,
    AddingButton,
    Textarea,
    Input,
    Rub
} = ui;


export const CreatingDeal = () => {


    /* hooks */
    hooks.useScrollToTop()
    const dispatch = useDispatch()
    const [getters, setters] = methods.useSetters()
    const sellerEmailFromStore = useSelector(selectors.getSellerEmail)
    const commission = useSelector(selectors.getCommission)
    const sumFromStore = useSelector(selectors.getSum)
    const buyerFromStore = useSelector(selectors.getBuyer)
    const sellerFromStore = useSelector(selectors.getSeller)


    /* mapping error */
    const [errorsObject, setErrorsObject] = React.useState<any>({})

    useEffect(() => {
        if (getters.errors.length) {
            setErrorsObject(methods.MappingErrors(getters.errors))
        }
    }, [getters.errors])


    /* state */
    const [fullNameBuyer, setFullNameBuyer] = React.useState<boolean>(false)
    const [fullNameSeller, setFullNameSeller] = React.useState<boolean>(false)

    const onSubmit = hooks.useScrollToError([errorsObject]);

    /* methods */
    const toggleFullNameSeller = () => setFullNameSeller(!fullNameSeller)
    const toggleFullNameBuyer = () => setFullNameBuyer(!fullNameBuyer)

    const name = hooks.useField({initial: '', placeholder: 'Наименование', error: errorsObject.name})
    const description = hooks.useField({initial: '', placeholder: 'Описание', error: errorsObject.description})

    /* full name client */
    /* seller */
    const sellerLastName = hooks.useField({initial: '', placeholder: 'Фамилия продавца'})
    const sellerFirstName = hooks.useField({initial: '', placeholder: 'Имя продавца'})
    const sellerMiddleName = hooks.useField({initial: '', placeholder: 'Отчество продавца'})

    const [cardNumberSeller, setCardNumberSeller] = useState<string>('')

    const buyerLastName = hooks.useField({initial: '', placeholder: 'Фамилия покупателя'})
    const buyerFirstName = hooks.useField({initial: '', placeholder: 'Имя покупателя'})
    const buyerMiddleName = hooks.useField({initial: '', placeholder: 'Отчество покупателя'})


    const buyerEmail = hooks.useField({
        initial: '',
        placeholder: 'Email покупателя',
        error: errorsObject.email_buyer,
    })
    const sellerEmail = hooks.useField({
        initial: String(sellerEmailFromStore || ''),
        placeholder: 'Email продавца',
        error: errorsObject.email_seller
    })
    const amount = hooks.useField({
        initial: String(sumFromStore || ''),
        placeholder: 'Сумма',
        mask: "9".repeat(10),
        error: errorsObject.amount,
    })


    /* Commission */
    const commissionValue = CommissionGenerate({
        commission: Number(commission),
        amount: Number(amount.value)
    })

    /* FIO */
    const sellerFio = {
        lastName: sellerLastName.value,
        firstName: sellerFirstName.value,
        middleName: sellerMiddleName.value,
    }

    const buyerFio = {
        lastName: buyerLastName.value,
        firstName: buyerFirstName.value,
        middleName: buyerMiddleName.value,
    }

    /* DAL */
    const {getClient, createDeal} = Thunk(setters)
    const handleSendData = () => {

        onSubmit();

        const validation: IErrorsFront[] = [
            {
                field: 'email_seller',
                message: 'Введите E-mail продавца',
                valid: !!sellerEmail.value
            },
            {
                field: 'email_buyer',
                message: 'Введите E-mail покупателя',
                valid: !!buyerEmail.value
            },
            {
                field: 'name',
                message: 'Введите имя',
                valid: !!name.value
            },
            {
                field: 'amount',
                message: 'Введите сумму',
                valid: !!amount.value
            },
            {
                field: 'description',
                message: 'Введите описание',
                valid: !!description.value
            },
            {
                field: 'card_number',
                message: 'Введите номер карты продавца',
                valid: cardNumberSeller.length === 19
            },
        ]

        if (
            amount.value
            && name.value
            && buyerEmail.value
            && sellerEmail.value
            && description.value
            && cardNumberSeller.length === 19
        ) {
            dispatch(getClient({
                email: sellerEmail.value,
                cardNumber: cardNumberSeller,
                clientType: 'setSeller',
                sellerFio: sellerFio,
                buyerFio: buyerFio
            }))
        } else {
            setters.setErrors(validation.filter((item) => !item.valid))
        }
    }

    useEffect(() => {
        if (sellerFromStore?.id !== null) {
            dispatch(getClient({
                email: buyerEmail.value,
                clientType: 'setBuyer',
                sellerFio: sellerFio,
                buyerFio: buyerFio
            }))
        }
    }, [sellerFromStore])

    useEffect(() => {
        if (
            getters.data
            && sellerFromStore?.id !== null
            && buyerFromStore?.id !== null
        ) {
            dispatch(createDeal({
                name: name.value,
                description: description.value,
                amount: Number(amount.value)
            }))
        }
    }, [sellerFromStore, buyerFromStore, getters.data])


    return (
        <section className='DealPage CreatingDeal Grid'>
            <h2 className='DealPageTitle'>Создание сделки</h2>
            <AlertContainer>
                <p>Инициатор сделки должен заполнить данные о товаре или услуге и способе доставки,
                    а так же информацию о себе. При этом инициатору сделки необходимо выбрать свою роль
                    (Продавец или Покупатель). Далее необходимо пригласить второго участника в сделку
                    путем передачи уникальной ссылки (по электронной почте). Второй участник так же должен
                    заполнить информацию о себе. После заполнения данных, каждый участник должен
                    подтвердить, что согласен с условиями сделки.</p>
            </AlertContainer>
            <div className='CreatingDeal__metadata DealFieldsContainer--large'>
                <Input label='Наименование' {...name}/>
                <div className='CreatingDeal__condition'>
                    <img className='CreatingDeal__condition__img' src={informationListItem} alt="#"/>
                    <div className='CreatingDeal__condition__text'>
                        Важные условия сделки: укажите как можно подробнее характеристики продаваемого товара.
                        Эта информация будет использоваться при возникновении спорных ситуаций.
                    </div>
                    <img className='CreatingDeal__condition__img' src={informationListItem} alt="#"/>
                </div>
                <Textarea label='Описание' maxLength={125} {...description}/>
                <Input placeholder='Сумма' support={<Rub/>} label='Сумма' {...amount}/>
            </div>
            <div className='CreatingDeal__information DealFieldsContainer--large'>
                <InformationBlock label='Продавец получает:'>
                    {commissionValue.sellerReceives}
                </InformationBlock>
                <InformationBlock label='Покупатель оплачивает:'>
                    {commissionValue.buyerPays}
                </InformationBlock>
                <InformationBlock label='Комиссия за сделку:'>
                    {commissionValue.commission}
                </InformationBlock>
            </div>
            <div className='CreatingDeal__user-data DealFieldsContainer--large'>
                <div className='CreatingDeal__user-data__content DealFieldsContainer--large'>
                    <Input label='E-mail покупателя' {...buyerEmail}/>
                    <AddingButton onClick={toggleFullNameBuyer} variant='icon'>
                        Добавить ФИО покупателя
                    </AddingButton>
                    {fullNameBuyer && (
                        <div className='CreatingDeal__user-data__fio-form'>
                            <Input label='Фамилия покупателя' {...buyerLastName}/>
                            <Input label='Имя покупателя' {...buyerFirstName}/>
                            <Input label='Отчество покупателя' {...buyerMiddleName}/>
                        </div>
                    )}
                </div>
                <div className='CreatingDeal__user-data__content DealFieldsContainer--large'>
                    <Input label='E-mail продавца' {...sellerEmail}/>
                    <AddingButton onClick={toggleFullNameSeller} variant='icon'>
                        Добавить ФИО продавца
                    </AddingButton>
                    {fullNameSeller && (
                        <div className='CreatingDeal__user-data__fio-form'>
                            <Input label='Фамилия продавца'  {...sellerLastName}/>
                            <Input label='Имя продавца'  {...sellerFirstName}/>
                            <Input label='Отчество продавца'  {...sellerMiddleName}/>
                        </div>
                    )}
                </div>
            </div>
            <div className='CreatingDeal__confirm DealFieldsContainer--large'>
                <Input
                    label='Номер карты продавца'
                    placeholder='Номер карты продавца'
                    mask='9999 9999 9999 9999'
                    value={cardNumberSeller}
                    error={errorsObject.card_number}
                    onChange={(e) => setCardNumberSeller(e.target.value)}
                />
                <ButtonWithAlert
                    onClick={handleSendData}
                    alertContent={(<Fragment>
                        <p>Продолжая, вы соглашаетесь с <Link to='/service-rules'>правилами</Link> сервиса</p>
                    </Fragment>)}>
                    Создать перевод
                </ButtonWithAlert>
            </div>
        </section>
    )
}