import React, {Fragment} from 'react'
import {hooks, ui} from "../../../ui";
import {methods} from "../../../../api/methods";
import {Thunk} from "../../../../store/DealReducer";
import {useDispatch} from "react-redux";

const {Input, Textarea, Button} = ui


export const ArbitrationForm = () => {


    /* hooks */
    const dispatch = useDispatch()
    const [getters, setters] = methods.useSetters()
    const {state, onChange, onSubmit} = hooks.useCallbackForm(getters.errors);
    const {startArbitration} = Thunk(setters)


    const handleSend = () => {


        if (onSubmit()) {
            dispatch(startArbitration({
                email: state.email.value,
                message: state.message.value,
                fullName: state.fullName.value
            }))
        }

    }


    return (
        <Fragment>
            <div className='Arbitration__form DealFieldsContainer--small'>
                <Input
                    label='Имя'
                    placeholder='Имя'
                    id='fullName'
                    onChange={onChange}
                    {...state.fullName}
                />
                <Input
                    label='Почта'
                    placeholder='Почта'
                    id='email'
                    onChange={onChange}
                    {...state.email}
                />
                <Textarea
                    label='Сообщение'
                    placeholder='Сообщение'
                    id='message'
                    onChange={onChange}
                    {...state.message}
                />
                <Button onClick={handleSend}>Отправить</Button>
            </div>
        </Fragment>
    )
}