import React, {FC, Fragment} from "react";
import './style.css'
import {Feedback} from "../../uiSections/Feedback";
import {StartSectionWave} from "../../uiSections/StartSectionWave";
import {commonImages} from "../../../../assets/images/common";
import {NoteElement} from "../../uiSections/NoteElement";
import {hooks} from "../../../ui";

const {
    messageIcon,
    returnIcon,
    arbitrationIcon
} = commonImages.statusIcons

export const SolvingConflicts: FC = () => {

    hooks.useScrollToTop()

    return (
        <Fragment>
            <section className='SolvingConflicts'>
                <StartSectionWave title='Решение споров'>
                    Мы являемся независимой третьей стороной, которая выступает
                    объективным арбитром в урегулировании споров между покупателями и продавцами.
                </StartSectionWave>

                <div className='ServiceRules__benefits Grid'>
                    <NoteElement img={messageIcon} title='Безопасная сделка'>
                        Сервис защиты покупателя и продавца в интернете. Безопасная сделка гарантирует 100% предоплату,
                        которая хранится на нашем счёте до тех пор пока продавец не выполнит все свои обязательства, а
                        покупатель не получит товар или услугу. Также безопасная сделка обеспечивает сохранность средств
                        покупателя: если продавец нарушает условия сделки, наш сервис возвращает средства или предлагает
                        покупателю скидку.
                    </NoteElement>
                    <NoteElement img={returnIcon} title='Возможность частичного или полного возврата'>
                        Для разрешения появившихся противоречий стороны могут условиться о возврате
                        продукта либо скидке на уже отправленный продукт. В данном случае средства или
                        возвратятся покупателю, или будет возвращена часть суммы, которую стороны согласуют меж собой.
                    </NoteElement>
                    <NoteElement img={arbitrationIcon} title='Споры'>
                        Если продукт либо услуга не соответствует утверждённым до этого чертам,
                        то клиент может выставить претензию торговцу. В данном случае средства будут
                        переданы торговцу лишь после того, как стороны придут к договоренности и претензия будет снята.
                    </NoteElement>
                </div>
            </section>
            <Feedback/>
        </Fragment>
    )
}